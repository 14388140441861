import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { registerLocaleData } from '@angular/common';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { NavigationMenu, NotificationDataService, NotificationType } from '@dpdhl/iot-shared-ui';
import { TranslocoService } from '@jsverse/transloco';
import { ViewConfigurationService } from '@dpdhl-iot/shared';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  applicationTitle: string[] = ['Condition', 'Monitoring'];
  navigationMenu: NavigationMenu[] = [];

  title = 'IOT.ConditionMonitoring.WebApp';
  languages = ['en', 'fr'];
  menuToggle = false;

  private subs: Subscription[] = [];

  constructor(
    private translateService: TranslateService,
    private toastNotificationService: NotificationDataService,
    private viewConfigurationService: ViewConfigurationService,
    private translocoService: TranslocoService
  ) {
    registerLocaleData(localeDe, 'de-DE', localeDeExtra);
    this.interceptAxios();
  }

  public ngOnInit(): void {
    const browserLang = this.translateService.getBrowserLang() ?? '';
    if (this.languages.indexOf(browserLang) > -1) {
      this.translateService.setDefaultLang(browserLang);
    } else {
      this.translateService.setDefaultLang('en');
    }

    this.subs.push(
      this.viewConfigurationService.currentViewConfiguration$.subscribe((viewConfiguration) => {
        this.translateService.setDefaultLang(viewConfiguration.language);
        this.translateService.currentLang = viewConfiguration.language;
        this.translateService.defaultLang = viewConfiguration.language;
        this.translocoService.setActiveLang(viewConfiguration.language);
        this.navigationMenu = viewConfiguration.navigationMenu;
      })
    );
  }

  toggleMenu(event: boolean): void {
    this.menuToggle = event;
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => {
      sub.unsubscribe();
    });
    this.subs = [];
  }

  protected interceptAxios(): void {
    axios.interceptors.response.use(
      async (response: AxiosResponse) => response,
      async (error: AxiosError) => {
        this.toastNotificationService.addNotification({
          text: error.message,
          type: NotificationType.ERROR,
        });
        await Promise.reject(error);
      }
    );
  }
}
