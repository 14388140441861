import { StatusColorClass } from '@dpdhl-iot/shared';
import { FacilityInfo, Org, SubOrg } from '@dpdhl-iot/api/backend';

export const facilityLegendColorClass = (facility: Org): string =>
  statusLegendColor(
    facility.isSelectable!,
    facility.totalDeviceCount! > 0,
    facility.totalActiveDeviceCount! > 0,
    facility.totalOpenAlertsCount! > 0,
    facility.totalAcceptedAlertsCount! > 0,
    facility.totalResolvingAlertsCount! > 0
  );

export const facilityInfoLegendColorClass = (facility: FacilityInfo): StatusColorClass =>
  statusLegendColor(
    facility.isSelectable!,
    facility.totalDevices! > 0,
    facility.totalActiveDevices! > 0,
    facility.hasOpenAlerts!,
    facility.hasAcceptedAlerts!,
    facility.hasResolvingAlerts!
  );

export const areaLegendColorClass = (room: SubOrg): StatusColorClass =>
  statusLegendColor(
    true,
    room.deviceCount! > 0,
    room.activeDeviceCount! > 0,
    room.totalOpenAlertsCount! > 0,
    room.totalAcceptedAlertsCount! > 0,
    room.totalResolvingAlertsCount! > 0
  );

const statusLegendColor = (
  isSelectable: boolean,
  hasDevices: boolean,
  hasActiveDevices: boolean,
  hasOpenAlerts: boolean,
  hasAcceptedAlerts: boolean,
  hasResolvingAlerts: boolean
): StatusColorClass => {
  if (!isSelectable) {
    return StatusColorClass.Grey;
  } else if (hasOpenAlerts || hasAcceptedAlerts) {
    return StatusColorClass.Red;
  } else if (
    !hasOpenAlerts &&
    !hasActiveDevices &&
    hasDevices &&
    !hasAcceptedAlerts &&
    !hasResolvingAlerts
  ) {
    return StatusColorClass.Yellow;
  } else if (!hasOpenAlerts && hasActiveDevices && !hasAcceptedAlerts && !hasResolvingAlerts) {
    return StatusColorClass.Green;
  } else if (
    !hasActiveDevices &&
    !hasDevices &&
    !hasOpenAlerts &&
    !hasAcceptedAlerts &&
    !hasResolvingAlerts
  ) {
    return StatusColorClass.Grey;
  }
  return StatusColorClass.Pink;
};
