import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, isDevMode } from '@angular/core';
import { AppComponent } from './component/app.component';
import { HttpClient, provideHttpClient } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MsalRedirectComponent } from '@azure/msal-angular';
import {
  ApplicationConfig,
  APPLICATION_CONFIG,
  ShellComponent,
  ShellRootModule,
  RELEASE_VERSION,
  APPLICATION_INSIGHTS_CONFIG,
  ApplicationInsightsConfig,
  MAPS_CONFIG,
  MapsConfig,
  getDefaultApplicationRoutes,
  GlobalErrorHandlerService,
  HELP_FILE_NAME,
  HELP_VERSION,
  WITH_USER_MANAGEMENT,
  GlobalDebugErrorHandlerService,
} from '@dpdhl/iot-shared-ui';
import { RouterModule, Routes } from '@angular/router';
import { AngularSvgIconModule } from 'angular-svg-icon';
import packageConfig from '../../../../package.json';
import { ButtonModule, DatePickerModule, SharedUiRootModule } from '@dpdhl/angular-shared-ui';
import {
  ApplicationUserManagementMapperService,
  SharedModule,
  TranslationLoaderFactory,
} from '@dpdhl-iot/shared';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { BackendApiModule, BackendConfiguration } from '@dpdhl-iot/api/backend';
import { ManagementApiModule, ManagementConfiguration } from '@dpdhl-iot/api/management';
import { PreprocessorApiModule, PreprocessorConfiguration } from '@dpdhl-iot/api/preprocessor';
import { DataApiModule, DataConfiguration } from '@dpdhl-iot/api/data';
import {
  RulesprocessorApiModule,
  RulesprocessorConfiguration,
} from '@dpdhl-iot/api/rules-processor';
import { PrognosisApiModule, PrognosisConfiguration } from '@dpdhl-iot/api/prognosis';
import { MSAL_CONFIG, MsalConfiguration } from '@dpdhl/angular-sso';
import { AuthorizationApiModule, AuthorizationConfiguration } from '@dpdhl-iot/api/authorization';
import {provideCharts, withDefaultRegisterables} from "ng2-charts";

const getApplicationConfig = () =>
  //eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/dot-notation
  (window as any)['__applicationConfiguration'] as ApplicationConfig;

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () =>
      import('./feature/dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'alerts',
    loadChildren: () => import('@dpdhl-iot/alert').then((m) => m.AlertModule),
  },
  {
    path: 'alert-rule-groups',
    loadChildren: () => import('@dpdhl-iot/alert-templates').then((m) => m.AlertTemplatesModule),
  },
  {
    path: 'notifications',
    loadChildren: () => import('@dpdhl-iot/notifications').then((m) => m.NotificationsModule),
  },
  {
    path: 'device-management',
    loadChildren: () =>
      import('@dpdhl-iot/device-management').then((m) => m.DeviceManagementModule),
  },
  {
    path: 'facilities',
    loadChildren: () => import('./feature/facility/facility.module').then((m) => m.FacilityModule),
  },
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    SharedUiRootModule.forRoot({
      assetVersion: packageConfig.semanticVersionCoMo,
    }),
    ShellRootModule,
    ShellComponent,
    AngularSvgIconModule.forRoot(),
    BrowserAnimationsModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslationLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ButtonModule,
    DatePickerModule.forRoot(),
    BackendApiModule.forRoot(
      () =>
        new BackendConfiguration({
          basePath: `${getApplicationConfig().baseUris.backend}`,
        }),
    ),
    ManagementApiModule.forRoot(
      () =>
        new ManagementConfiguration({
          basePath: `${getApplicationConfig().baseUris.platform}management`,
        }),
    ),
    AuthorizationApiModule.forRoot(
      () =>
        new AuthorizationConfiguration({
          basePath: `${getApplicationConfig().baseUris.platform}authorization`,
        }),
    ),
    PreprocessorApiModule.forRoot(
      () =>
        new PreprocessorConfiguration({
          basePath: `${getApplicationConfig().baseUris.platform}preprocessor`,
        }),
    ),
    DataApiModule.forRoot(
      () =>
        new DataConfiguration({
          basePath: `${getApplicationConfig().baseUris.platform}data`,
        }),
    ),
    RulesprocessorApiModule.forRoot(
      () =>
        new RulesprocessorConfiguration({
          basePath: `${getApplicationConfig().baseUris.platform}rulesprocessor`,
        }),
    ),
    PrognosisApiModule.forRoot(
      () =>
        new PrognosisConfiguration({
          basePath: getApplicationConfig().baseUris.additionalUris?.[0],
        }),
    ),
    RouterModule.forRoot(
      getDefaultApplicationRoutes(routes, { withUserManagement: true, withSettings: true }),
      {
        anchorScrolling: 'enabled',
      },
    ),
  ],
  providers: [
    { provide: APPLICATION_CONFIG, useValue: getApplicationConfig() },
    { provide: RELEASE_VERSION, useValue: packageConfig.semanticVersionCoMo },
    { provide: HELP_VERSION, useValue: packageConfig.semanticVersionCoMo },
    {
      provide: HELP_FILE_NAME,
      useFactory: () => {
        return (useCaseId: string) => {
          if (
            [
              'exp-predictive-maintenance',
              'exp-pm-cgn-hub',
              'exp-pm-muc-gtw',
              'ecs-pm-ewr',
              'pnp-pm-ascii',
            ].indexOf(useCaseId) !== -1
          ) {
            return 'help/predmain/help.md';
          }
          return 'help/condmon/help.md';
        };
      },
    },
    {
      provide: MSAL_CONFIG,
      //eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/dot-notation
      useValue: (window as any)['__msalConfig'] as MsalConfiguration,
    },
    {
      provide: APPLICATION_INSIGHTS_CONFIG,
      //eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/dot-notation
      useValue: (window as any)['__appInsightsConfig'] as ApplicationInsightsConfig,
    },
    {
      provide: MAPS_CONFIG,
      //eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/dot-notation
      useValue: (window as any)['__mapsConfig'] as MapsConfig,
    },
    {
      provide: WITH_USER_MANAGEMENT,
      useExisting: ApplicationUserManagementMapperService,
    },
    {
      provide: ErrorHandler,
      useClass: isDevMode() ? GlobalDebugErrorHandlerService : GlobalErrorHandlerService,
    },
    provideHttpClient(),
    provideCharts(withDefaultRegisterables())
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
